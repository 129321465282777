import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import tw, { styled } from "twin.macro"
import { Hero } from "../components/hero"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import Img from "gatsby-image"

export const query = graphql`
  query NewsList($skip: Int, $limit: Int) {
    allSanityNews(
      skip: $skip
      limit: $limit
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        slug {
          current
        }
        title
        tagline
        _createdAt(formatString: "dddd MMMM Do, YYYY hh:mma")
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }

    sanityPage(slug: { current: { eq: "news" } }) {
      hero {
        _key
        headline
        tagline
        background {
          asset {
            url
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, grayscale: true, maxWidth: 2000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

let Container = tw.section`
    container py-4 -mt-16
`

let NewsCard = tw.div`
shadow-lg  mb-6 text-lg flex flex-col lg:flex-row bg-white mx-auto rounded-lg font-sans overflow-hidden
`

let NewsCards = tw.div`
    max-w-4xl mx-auto
`

let H2 = tw.h2`
 text-2xl font-bold pb-4 text-blue-900 leading-tight
`

let TaglineText = tw.p` 
pb-4 text-gray-700 text-base
`

let SubmittedDate = tw.p`
   pb-4 text-gray-700 text-sm
`

let Button = tw.div`
text-white bg-primary inline-block py-2 px-4 cursor-pointer self-start
`

let Icon = styled(FontAwesomeIcon)`
  ${tw`mx-2 `}
`

let Image = styled(Img)`
  ${tw`lg:w-1/3 lg:rounded-lg lg:rounded-r-none`};
`

let NewsContent = tw.div`
flex flex-col py-6 px-8 lg:w-2/3 self-stretch
   
`

let NewsPages = props => {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/news" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <Layout>
      <SEO
        title={"News"}
        image={props.data.sanityPage.hero.background.asset.url}
      />
      <Hero key={"hero"} {...props.data.sanityPage?.hero} />
      <Container>
        <NewsCards>
          {props?.data?.allSanityNews.nodes.map(news => (
            <NewsCard>
              {news.image && (
                <Image
                  fluid={{ ...news.image.asset.fluid, aspectRatio: 21 / 9 }}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              )}
              <NewsContent>
                <Link to={`/news/${news.slug.current}`}>
                  <H2>{news.title}</H2>
                </Link>
                {news.tagline && <TaglineText>{news.tagline}</TaglineText>}
                <SubmittedDate>{news._createdAt}</SubmittedDate>
                <Button>
                  <Link to={`/news/${news.slug.current}`} rel="readMore">
                    Read more
                    <Icon icon={faArrowRight} />
                  </Link>
                </Button>
              </NewsContent>
            </NewsCard>
          ))}
          {!isFirst && (
            <Button>
              <Link to={prevPage} rel="prev">
                <Icon icon={faArrowLeft} />
                Previous Page
              </Link>
            </Button>
          )}
          {!isLast && (
            <Button>
              <Link to={nextPage} rel="next">
                Next Page
                <Icon icon={faArrowRight} />
              </Link>
            </Button>
          )}
        </NewsCards>
      </Container>
    </Layout>
  )
}

export default NewsPages
